import React, { useEffect, useState } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import "./PayPageButton.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ButtonWrapper = ({ currency }) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  const [amount, setAmount] = useState()

  const updateAmount = (event) => {
    setAmount(event.target.value)
  }

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency
      },
    });
  }, [currency]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="pay-pal-input">
        <label htmlFor="pay-input">Add your amount</label>
        <input type="number" className="pay-button-wrapper" id="pay-input" onChange={(event) => updateAmount(event)} />
      </div>

      <PayPalButtons
        fundingSource="paypal"
        style={{"layout":"vertical","label":"donate"}}
        disabled={false}
        forceReRender={[amount]}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    value: amount,
                    breakdown: {
                      item_total: {
                        currency_code: "USD",
                        value: amount,
                      },
                    },
                  },
                  items: [
                    {
                      name: "Charitable contribution to 501(c)(3) nonprofit EIN: 88-2000579",
                      quantity: "1",
                      unit_amount: {
                        currency_code: "USD",
                        value: amount,
                      },
                      category: "DONATION",
                    },
                  ],
                },
              ],
            })
            .then((orderId) => {
              return orderId;
            })
        }}
        onApprove={(data, actions) => {
          return actions.order
            .capture().then(() => {
              return toast.success('Your donation was successfully sent!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
            })
        }}
        onError={() => {
          toast.error('Something went wrong', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }}
      />
    </>
  );
} 

export default function PayPalButton() {
  const CLIENT_ID = "ARg1ibkjtXtQ7_l4so7-OagFxOlZPu6EUwiC4Bi_kg49SrCNmWj4Q4Wdk0dWJzov7c3E43k556l2Q1qZ";

  return (
    <div className="pay-pal">
      <PayPalScriptProvider
          options={{
            "client-id": CLIENT_ID,
            components: "buttons",
            currency: "USD",
            locale: "en_US"
          }}
      >
          <ButtonWrapper
            currency={"USD"}
          />
      </PayPalScriptProvider>
    </div>
  );
}
