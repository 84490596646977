import { graphql, useStaticQuery } from 'gatsby';

const useZelleQuery = () => {
  return useStaticQuery(graphql`
    query Zelle {
      contentfulZelle {
        title
        email
      }
    }
  `);
};

export default useZelleQuery;