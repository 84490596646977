import { graphql, useStaticQuery } from 'gatsby';

const usePayPageHeadlineQuery = () => {
  return useStaticQuery(graphql`
    query PayPageHeadline {
      contentfulPayPageHeadline {
        title
        subTitle
        amountOverview
        amount
      }
    }
  `);
};

export default usePayPageHeadlineQuery;