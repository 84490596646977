import { graphql, useStaticQuery } from 'gatsby';

const useTabBarPanelInfoQuery = () => {
  return useStaticQuery(graphql`
    query TabBarPanelInfo {
      contentfulTabBarMain {
        tabBarPanel {
          ... on ContentfulTabBarPanelInfo {
            title
            fieldName
            fieldValue
          }
        }
      }
    }
  `);
};

export default useTabBarPanelInfoQuery;