import React, { useState } from "react";
import { Tabs, useTabState, usePanelState } from "@bumaga/tabs";
import PayPalButton from "../PayPalButton/PayPalButton";
import useZelleQuery from "../../graphql/zelle";
import useTabBarPanelInfoQuery from "../../graphql/tab-bar-panel-info"
import "./TabBar.scss";
import PayPal from "../../images/PayPalLogo.png";
import Zelle from "../../images/Zelle.png";
import Copy from "../../images/clarity_copy-line.svg";

const Tab = ({ children }) => {
  const { onClick, isActive } = useTabState();

  return <button className={isActive ? "tab tab__active" : "tab"} onClick={onClick}>{children}</button>;
};

const Panel = ({ children }) => {
  const isActive = usePanelState();

  return isActive ? <div className="panel">{children}</div> : null;
};

const TabBar = () => {
  const [ isShowCopyNotification, setIsShowCopyNotification ] = useState(false);
  const [ currentValue, setCurrentValue ] = useState("")
  const zelleData = useZelleQuery();
  const tabBarPanelData = useTabBarPanelInfoQuery();

  const CopyText = (value) => {
    window.navigator.clipboard.writeText(value);
    setCurrentValue(value)
    setIsShowCopyNotification(true);
    setTimeout(() => {
      setIsShowCopyNotification(false);
    }, 3000)
  }

  return(
    <section className="tab-bar section-container">
      <Tabs>
        <div>
          <Tab>PayPal</Tab>
          <Tab>{zelleData?.contentfulZelle.title}</Tab>
          {tabBarPanelData?.contentfulTabBarMain.tabBarPanel.map((item) => <Tab>{item.title}</Tab>)}
        </div>
        <Panel>
          <div className="panel__item">
            <img src={PayPal} alt="PayPal" />
            <p>Transfer money online in seconds with PayPal money transfer. All you need is an email address.</p>
          </div>
          <div className="panel__item">
            <PayPalButton />
          </div>
        </Panel>
        <Panel>
          <div className="panel__item">
            <img src={Zelle} alt="Zelle" width={69} />
            <p>Zelle® is a fast, safe and easy way to send and receive money with friends, family and others you trust.</p>
          </div>
          <div className="panel__item item-input">
            <input type="text" value={zelleData?.contentfulZelle.email} />
            <span className={`zelle__copy-notification ${isShowCopyNotification ? "show" : "hide"}`}>Email was copied</span>
            <img className="copy-btn-img" src={Copy} onClick={() => CopyText(zelleData?.contentfulZelle.email)} title="Copy" alt="Copy" />
          </div>
        </Panel>
        {tabBarPanelData?.contentfulTabBarMain.tabBarPanel.map((item) => {
          return(
            <Panel>
              <ul className="panel__item-list">
                {item?.fieldName.map((fieldName, index) => {
                  return(
                    <li>
                      <p className="field-name">{fieldName}</p>
                      <p>
                        {item?.fieldValue[index]}
                      </p>
                      <p className={`copy-notification ${isShowCopyNotification && (currentValue === item?.fieldValue[index]) ? "show" : "hide"}`}>Field was copied</p>
                      <img className="copy-btn-img" src={Copy} onClick={() => CopyText(item?.fieldValue[index])} title="Copy" alt="Copy" />
                    </li>
                    )
                })}
              </ul>
            </Panel>
          )
        })}
      </Tabs>
    </section>
  )
};

export default TabBar;